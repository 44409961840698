/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import Header from "../header/header";
import Footer from "../footer/footer";

import CookieConsent from "react-cookie-consent";

import "./layout.scss";

const Layout = ({ title, headerData, footerData, children }) => {
  return (
    <>
      { headerData && <Header title={title} headerConfigs={headerData} /> }

      <main>{children}</main>

      { footerData && <Footer footerConfigs={footerData} /> }

      <CookieConsent
        location='bottom'
        buttonWrapperClasses='button-wrapper'
        buttonClasses='accept-button'
        disableStyles='true'
        buttonText={getAcceptText()}
        cookieName='gatsby-gdpr-google-analytics'
        containerClasses='cookie-container'
        contentClasses='cookie-content'
      >
        By browsing this website, you agree to our use of cookies as described in our <a href="/privacy" target="_blank">Privacy Policy</a>.
      </CookieConsent>
    </>
  )
}

const getAcceptText = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-606 -19)">
        <rect fill="none" width="24" height="24" transform="translate(606 19)"/>
        <g transform="translate(-2703.5 -3313.5)">
          <line fill="none" style={{stroke: '#273249', strokeLinecap: 'round', strokeWidth: '2px'}} x2="16" y2="16" transform="translate(3313.5 3336.5)"/>
          <line fill="none" style={{stroke: '#273249', strokeLinecap: 'round', strokeWidth: '2px'}}  x1="16" y2="16" transform="translate(3313.5 3336.5)"/>
        </g>
      </g>
    </svg>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  headerData: PropTypes.object,
  footerData: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Layout
