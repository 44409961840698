const GetWebpFormat = (url) => {
  const extension = url.substr(url.lastIndexOf('.') + 1);

  if (extension === 'gif') return url;

  const insertResponsiveParamsAt = 'upload/';
  const indexToInsertResponsiveParams = url.indexOf(insertResponsiveParamsAt) + (insertResponsiveParamsAt.length);

  const responsiveParams = `f_auto/`;

  return url.substr(0, indexToInsertResponsiveParams) + responsiveParams + url.substr(indexToInsertResponsiveParams);
}

export default GetWebpFormat;
