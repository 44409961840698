import React from "react"

import "./footer.scss"
import GetWebpFormat from "../../../utils/getWebpFormat"

const Footer = ({ footerConfigs }) => {
  return (
    <footer>
      <section className="footer-wrapper">
        <div className="footer-content">
          <div className="brand-info">
            <div className="logo-wrapper">
              <img
                src={GetWebpFormat(footerConfigs.brand_logo.image.url)}
                alt={footerConfigs.brand_logo.image.alt_text || "Listener Logo"}
              />
              <p>
                <a
                  className="built-with-love-link"
                  href="https://truesparrow.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <strong>Built with</strong> <em>♥</em>
                  </span>
                  <strong>by True Sparrow</strong>
                </a>
              </p>

              <ul className="social-links">
                {footerConfigs.links_to_social_media.social_profiles.map(
                  (socialProfile, index) => {
                    return (
                      <li key={`social-profile-${index}`}>
                        <a
                          href={socialProfile.url}
                          title={socialProfile.text}
                          target={socialProfile.target}
                          rel="noreferrer"
                        >
                          <img
                            src={
                              socialProfile.image &&
                              GetWebpFormat(socialProfile.image.url)
                            }
                            alt={socialProfile.alt_text}
                          />
                        </a>
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
          </div>

          <nav className="footer-navigation">
            {footerConfigs.footer_menus.map((footerMenu, index) => {
              return (
                <ul key={`footer-menu-${index}`}>
                  <li className="section-header">{footerMenu.heading}</li>

                  {footerMenu.links.map((footerLink, index) => {
                    return (
                      <li key={footerLink.text}>
                        <a href={footerLink.url} target={footerLink.target}>
                          {footerLink.text}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              )
            })}
          </nav>
        </div>

        <div className="copyright-info">
          <div className="sqreen-badge">
            <a
              href={footerConfigs.link_to_sqreen}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={GetWebpFormat(footerConfigs.sqreen_badge.url)}
                alt="Protected by sqreen"
              />
            </a>
          </div>
          <p>{footerConfigs.copyright_text}</p>
        </div>
      </section>
    </footer>
  )
}

export default Footer
