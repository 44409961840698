import React, { useState } from "react";
import PropTypes from "prop-types";

import "./header.scss"
import GetWebpFormat from "../../../utils/getWebpFormat";
import { sendAmplitudeData } from "../../../utils/amplitude"
import { AmplitudeEvents } from "../../../types/amplitudeEvents"

const Header = ({ title, headerConfigs }) => {
  const [isHeaderMenuOpen, setHeaderMenu] = useState(false);

  const toggleHeaderMenu = () => {
    setHeaderMenu(!isHeaderMenuOpen);
  };

  const onClickHandler = (url)=> () => {
    sendAmplitudeData(AmplitudeEvents.SIGNIN_BTN_CLICKED);
    window.location.href=url;
  }

  const getViewTypeClassName = (viewType) => {
    return ((viewType === 'all' || viewType === null) ?
      '' :
      (viewType === 'web' ? 'hidden-xs' : 'hidden-lg')
    );
  }

  return (
    <header>
      <div className="row">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo-wrapper">
              <figure aria-label="primary navigation supporting logo">
                <a href={headerConfigs.brand_logo.url}>
                  <p>Listener</p>
                  <img src={GetWebpFormat(headerConfigs.brand_logo.image.url)} alt={headerConfigs.brand_logo.image.alt_text || 'Listener Logo'} />
                </a>
              </figure>
            </div>

            <label className="menu-toggle noselect" htmlFor="header-toggle-switch">
              <input type="checkbox" id="header-toggle-switch" value={isHeaderMenuOpen} onClick={toggleHeaderMenu}/>

              <div className="button-group">
                <svg className="hamburger-icon" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70"><g transform="translate(-316 5)"><g transform="translate(0 1)"><rect fill="#273249" width="28" height="3" rx="1.5" transform="translate(337 18)"/><rect fill="#273249" width="28" height="3" rx="1.5" transform="translate(337 28)"/><rect fill="#273249" width="28" height="3" rx="1.5" transform="translate(337 38)"/></g></g></svg>
                <svg className="close-icon" xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69"><g transform="translate(-316 5)"><g transform="translate(339.411 18.411)"><rect fill="#273249" width="27.107" height="3.696" rx="1.848" transform="translate(0 19.168) rotate(-45)"/><rect fill="#273249" width="4" height="4" rx="2" transform="translate(9.589 8.589)"/><rect fill="#273249" width="27.107" height="3.696" rx="1.848" transform="translate(2.614 0) rotate(45)"/></g></g></svg>
              </div>
            </label>

            <nav className={`main-navigation noselect ${isHeaderMenuOpen ? 'open' : ''}`} aria-label="primary navigation">
              <ul className="menu-items">
                {
                  Object.keys(groupBy(headerConfigs.product_sub_menus, 'belongs_to')).map((item, index) => {
                    return (
                      <li className="has-sub-menu" key={`sub-menu-item-${index}`}>
                        <label className="header-link" htmlFor={`sub-menu-toggle-${item}`}>
                          <span>{item}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9.88" viewBox="0 0 16.265 9.382"><path d="M115.452,8.278l6.365,6.365,6.365-6.365" transform="translate(-113.684 -6.511)" fill="none" stroke="#273249" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5px" /></svg>
                        </label>

                        <input type="checkbox" id={`sub-menu-toggle-${item}`} />

                        <div className="sub-menu-wrapper">
                          <div className="sub-menu">
                            {
                              groupBy(headerConfigs.product_sub_menus, 'belongs_to')[item].map((subMenu, index) => {
                                return (
                                  <div className="sub-menu-section" key={`sub-menu-${index}`}>
                                    <h6>{subMenu.heading}</h6>
                                    <p>{subMenu.description}</p>

                                    <ul className="sub-menu-items">
                                      {
                                        subMenu.links.map((link, index) => {
                                          return (
                                            <li key={index}>
                                              <a href={link.url} target={link.target}><svg xmlns="http://www.w3.org/2000/svg" width="6.414" height="10.828" viewBox="0 0 6.414 10.828"><path d="M115.452,8.278l4,4,4-4" transform="translate(-6.864 124.866) rotate(-90)" fill="none" stroke="#57a0ad" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px" /></svg>
                                                {link.text}
                                              </a>
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
                {
                  headerConfigs.menu_links.map((menuLink, index) => {
                    return (
                      <li key={`menu-link-${index}`} className={`${getViewTypeClassName(menuLink.view_type)}`}>
                        {index === 2 ?
                        <a
                          className={`
                            header-link
                            ${menuLink.link_style === 'link' ? '' : 'button-style'}
                            ${title === menuLink.text ? 'active': ''}
                          `}
                          onClick={onClickHandler(menuLink.url)}
                        >
                          <span>{menuLink.text}</span>
                        </a>
                        :
                        <a
                          className={`
                            header-link
                            ${menuLink.link_style === 'link' ? '' : 'button-style'}
                            ${title === menuLink.text ? 'active': ''}
                          `}
                          href={menuLink.url}
                        >
                          <span>{menuLink.text}</span>
                        </a>
                  }
                      </li>
                    )
                  })
                }
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(obj);

    return acc;
  }, {});
}


Header.propTypes = {
  title: PropTypes.string,
  headerConfigs: PropTypes.object,
}

export default Header
